export enum PERMISSION_KEYS {
  DASHBOARD = 'Dashboard',

  // users
  USERS = 'Users',
  DRIVERS = 'Drivers',
  PASSENGER = 'Passenger',
  ROLES = 'Roles',

  TRAVEL_PREFERENCES = 'Travel & Preferences',
  // Manage Documents
  MANAGE_DOCUMENTS = 'Manage Documents',
  DOCUMENT_QUESTIONNAIRE = 'Document Questionnaire',

  MANAGE_VEHICLE = 'Manage Vehicle',

  COMPLAINT = 'Complaint',

  // Manage Price
  PRICE_CATALOGUE = 'Price Catalogue',
  COMMISSION = 'Commission',
  FINANCIAL_YEAR = 'Financial Year',

  // Informative
  PRIVACY_POLICY = 'Privacy Policy',
  TERMS_CONDITIONS = 'Terms & Conditions',
  FAQ = 'Faq',
  ABOUT_US = 'About Us',
  SUPPORT_CONTACT = 'Support & Contact',

  // Settings
  EMAIL_SETTING = 'Email Setting',
  ACCOUNT_SETTING = 'Account Setting',
  THEME_SETTING = 'Theme Setting',
  MANAGE_LINKS = 'Manage Links',
  NOTIFICATION = 'Notifications',

  OFFER_RIDES = 'Offer Rides',
  SETTINGS = 'Settings',
  BOOKINGS = 'Bookings',
  SPAMREPORT = 'SpamReport ',

  COUNTRY_STATE = 'Country & State',
  MANAGE_COUPON = 'Manage Coupon',
  MANAGE_RIDE = 'Manage Ride',
  EARNING_REPORTS = 'Earning & Payout',
  WALLET_DETAIL = 'Wallet Details',
  COMMISSION_REPORT = 'Commission Report',
  SEND_NOTIFICATION = 'Send Notification',
  PAYOUTS = 'Payouts'
}
